import React, { useCallback, useEffect, useState } from "react";

import { gql, useMutation } from "@apollo/client";
import logo from "../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [validated, setValidated] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const [doForgotPassword, { data, loading, error }] = useMutation(
    gql`
      mutation Mutation($username: String!) {
        forgot_password(username: $username)
      }
    `
  );
  useEffect(() => {
    let errors = {};
    let canSubmit = true;
    if (!username) {
      errors.username = "Veuillez saisir votre e-mail";
      canSubmit = false;
    } else if (!username.includes("@")) {
      errors.username = "Veuillez saisir un e-mail valide";
      canSubmit = false;
    }

    setErrors(errors);
    setCanSubmit(canSubmit);
  }, [username, setErrors]);

  useEffect(() => {
    if (error) {
      switch (error?.message) {
        case "user_not_found":
          setErrors({
            username: "Cet identifiant n'existe pas",
          });

          break;
        default:
          setErrors({
            username: "Identifiant incorrect",
          });
          break;
      }
      setValidated(true);
    }
  }, [data, error, setValidated, dispatch, setErrors]);

  const submit = useCallback(
    (event) => {
      (async () => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setErrors({});

        try {
          let res = await doForgotPassword({
            variables: {
              username,
            },
          });
          if (res.data.forgot_password) {
            toast.success(
              "Un e-mail de réinitialisation de mot de passe a été envoyé à l'adresse e-mail saisie."
            );
          }
        } catch (error) {
          console.error(error);
        }
      })();
    },
    [doForgotPassword, username, setValidated]
  );

  return (
    <div className="row w-100">
      <div className="col-md-6">
        <div
          className="d-flex align-items-center w-100"
          style={{
            minHeight: "100vh",
          }}
        >
          <div className="w-100">
            <img
              src={logo}
              className="app-logo w-100 d-block"
              alt="Easyhpad logo"
              style={{ maxWidth: "300px", margin: "auto" }}
            />
          </div>
        </div>
      </div>
      <div
        className="col-md-6"
        style={{
          backgroundColor: "rgb(249, 249, 250)",
          boxShadow: "0 20px 60px rgba(105, 158, 158, 0.3)",
        }}
      >
        <div
          className="flex-row align-items-center"
          style={{
            display: "flex",
            minHeight: "100vh",
          }}
        >
          <div className="w-100" style={{ maxWidth: "25rem", margin: "auto" }}>
            <h1
              className="w-100 text-center"
              style={{ fontSize: "1.4em", fontWeight: "700" }}
            >
              Réinitialisation de mot de passe
            </h1>
            <form onSubmit={submit}>
              <div className="mb-4 form-field">
                <label htmlFor="email" className="form-label">
                  E-mail <span className="required">*</span>
                </label>
                <div className="text-secondary fst-italic mb-3">
                  Un e-mail de réinitialisation de mot de passe sera envoyé à
                  l'adresse e-mail saisie.
                </div>
                <input
                  type="email"
                  className={
                    "form-control" +
                    (validated && errors.username ? " is-invalid" : "")
                  }
                  id="email"
                  placeholder="E-mail"
                  name="email"
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                  value={username}
                />
                {errors.username && (
                  <div className="invalid-feedback">{errors.username}</div>
                )}
              </div>

              <div className="mb-4 form-field">
                <Link to="/login">Se connecter</Link>
              </div>
              <div className="w-100">
                <button
                  type="submit"
                  className="btn btn-primary float-end"
                  disabled={loading || !canSubmit}
                >
                  Démander la réinitialisation
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
