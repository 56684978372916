import { useDispatch, useSelector } from "react-redux";

import { setOpenedDocument } from "../../redux/features/openedDocumentSlice";
import { getDocumentDownloadUrl } from "../../services/DocumentUtil";
import { useEffect } from "react";

export const DocumentOpenedModal = ({ onClosed }) => {
  const dispatch = useDispatch();
  const openedDocument = useSelector((state) => state.openedDocument);

  useEffect(() => {
    if (openedDocument) {
      const modal = window.bootstrap.Modal.getOrCreateInstance(
        document.getElementById("document-open-modal")
      );

      modal._element.addEventListener("hidden.bs.modal", () => {
        dispatch(setOpenedDocument(null));
      });

      if (openedDocument) {
        modal.show();
      }
    }
  }, [openedDocument, dispatch, onClosed]);

  if (!openedDocument) {
    return null;
  }
  const documentUrl = getDocumentDownloadUrl(openedDocument);
  let previewComponent = null;
  let showDownladButton = true;
  switch (openedDocument.mime_type) {
    case "application/pdf":
      previewComponent = (
        <embed
          src={documentUrl + "&inline=1#toolbar=0&navpanes=0"}
          type="application/pdf"
          width="100%"
        />
      );
      break;
    case "image/jpeg":
    case "image/png":
      previewComponent = (
        <img
          className="img-fluid"
          src={documentUrl}
          alt={openedDocument.name}
          width="100%"
        />
      );
      break;
    default:
      showDownladButton = false;
      previewComponent = (
        <div className="text-center">
          <p>La prévisualisation n'est pas disponible</p>
          <a
            href={documentUrl}
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-download  me-2 cursor-pointer"></i>
            Télécharger
          </a>
        </div>
      );
      break;
  }
  return (
    <div className="modal fade d-flex" id="document-open-modal" tabIndex="-1">
      <div className="modal-dialog modal-xl d-flex flex-column w-100">
        <div className="modal-content flex-grow-1">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {openedDocument?.name}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                dispatch(setOpenedDocument(null));
              }}
            ></button>
          </div>
          <div className="modal-body d-flex flex-grow-1">
            {previewComponent}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary me-2"
              data-bs-dismiss="modal"
              onClick={() => {
                dispatch(setOpenedDocument(null));
              }}
            >
              Fermer
            </button>
            {showDownladButton && (
              <a
                href={documentUrl}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download  me-2 cursor-pointer"></i>
                Télécharger
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentOpenedModal;
