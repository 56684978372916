import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { setUser } from "../redux/features/userSlice";
import { setSelectedAccount } from "../redux/features/accountSlice";
import { setSelectedFacilities } from "../redux/features/facilitySlice";

export default function UserStateComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const jwt_token = useSelector((state) => state.jwt_token);
  const user = useSelector((state) => state.user);

  const [lastPath, setLastPath] = useState("/");

  useEffect(() => {
    if (user) {
      if (localStorage.getItem("selected_account")) {
        let selectedAccount = user.accounts.find(
          (account) =>
            parseInt(account.id) ===
            parseInt(localStorage.getItem("selected_account"))
        );
        if (selectedAccount) {
          dispatch(setSelectedAccount(selectedAccount));

          let newSelectedFacilities = selectedAccount.facilities;
          if (localStorage.getItem("selected_facilities")) {
            let selectedFacilities = JSON.parse(
              localStorage.getItem("selected_facilities")
            );
            newSelectedFacilities = newSelectedFacilities.filter((facility) =>
              selectedFacilities.includes(parseInt(facility.id))
            );
          }
          dispatch(setSelectedFacilities(newSelectedFacilities));
        }
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (location.pathname !== "/login" && location.pathname !== lastPath) {
      setLastPath(location.pathname);
    }
  }, [location, setLastPath, lastPath]);

  const [getMe, { loading: loadingUser, data }] = useLazyQuery(
    gql`
      query {
        me {
          id
          email
          firstname
          lastname
          accounts {
            id
            name
            manager {
              id
              name
              logo_url
            }
            facilities {
              id
              name
            }
            permissions
            role
          }
        }
      }
    `,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    getMe();
  }, [jwt_token, getMe]);

  useEffect(() => {
    if (loadingUser || !data) {
      return;
    }

    dispatch(setUser(data?.me ?? null));

    if (data && data.me) {
      if (location.pathname === "/login") {
        if (
          !lastPath.startsWith("/forgot-password") &&
          !lastPath.startsWith("/reset-password")
        ) {
          navigate(lastPath);
        } else {
          navigate("/");
        }
      }
    }
    if (
      !data?.me &&
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/forgot-password") &&
      !location.pathname.startsWith("/reset-password")
    ) {
      navigate("/login");
    }
  }, [data, dispatch, loadingUser, navigate, lastPath, location]);

  return null;
}
