import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice.js";
import jwtTokenSlice from "./features/jwtTokenSlice.js";
import accountSlice from "./features/accountSlice.js";
import facilitySlice from "./features/facilitySlice.js";
import openedDocumentSlice from "./features/openedDocumentSlice.js";

export default configureStore({
  reducer: {
    user: userReducer,
    jwt_token: jwtTokenSlice,
    selectedAccount: accountSlice,
    selectedFacilities: facilitySlice,
    openedDocument: openedDocumentSlice,
  },
});
