import React, { Suspense, useEffect, useState } from "react";
import "./scss/style.scss";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import Login from "./pages/Login.js";
import UserStateComponent from "./components/UserStateComponent.js";

import { useSelector } from "react-redux";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import Loader from "./views/common/Loader.js";
import DocumentOpenedModal from "./components/document/DocumentOpenedModal.js";
import ConfirmationModal from "./components/common/ConfirmationModal.js";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/ForgotPassword.js";
import ResetPassword from "./pages/ResetPassword.js";

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          exact: true,
          path: "/login",
          name: "Se connecter",
          element: <Login />,
        },
        {
          exact: true,
          path: "/forgot-password",
          name: "Mot de passe oublié",
          element: <ForgotPassword />,
        },
        {
          exact: true,
          path: "/reset-password/:token",
          name: "Mot de passe oublié",
          element: <ResetPassword />,
        },

        {
          index: true,
          element: <DefaultLayout />,
        },
        {
          path: "*",
          element: <DefaultLayout />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <DocumentOpenedModal />
      <ConfirmationModal />

      <RouterProvider router={router} />
    </div>
  );
}

export default App;

const Root = () => {
  const [client, setClient] = useState(getApolloClient());
  const jwt_token = useSelector((state) => state.jwt_token);
  useEffect(() => {
    setClient(getApolloClient(jwt_token));
  }, [jwt_token]);

  return (
    <>
      <ApolloProvider client={client}>
        <Suspense fallback={<Loader />}>
          <UserStateComponent />

          {/* {init_loading && <Loader />} */}
          <Outlet></Outlet>

          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Suspense>
      </ApolloProvider>
    </>
  );
};

const getApolloClient = (jwtToken) => {
  let apiHost = "";
  if (window.location.hostname.indexOf("localhost") !== -1) {
    apiHost = "http://localhost:4000";
  } else {
    apiHost = `https://api.easyhpad.com`;
  }

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: `${apiHost}/graphql`,
      headers: {
        authorization: jwtToken ? `Bearer ${jwtToken}` : "",
      },
    }),
  });
};
