import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "selectedFacilities",
  initialState: [],
  reducers: {
    setSelectedFacilities(_state, action) {
      return action.payload;
    },
  },
});

export const { setSelectedFacilities } = slice.actions;
export default slice.reducer;
