import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "openedDocument",
  initialState: null,
  reducers: {
    setOpenedDocument(_state, action) {
      return action.payload;
    },
  },
});

export const { setOpenedDocument } = slice.actions;
export default slice.reducer;
