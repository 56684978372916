import React, { Component } from "react";

export default class ConfirmationModal extends Component {
  static confirm({
    title,
    message,
    yesLabel = "Oui",
    noLabel = "Non",
    onYes,
    onNo = () => {},
  }) {
    ConfirmationModal.__singletonRef.setState({
      show: true,
      title,
      message,
      yesLabel,
      noLabel,
      onYes,
      onNo,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "",
      message: "",
      yesLabel: "",
      noLabel: "",
      onYes: () => {},
      onNo: () => {},
    };
    ConfirmationModal.__singletonRef = this;
  }

  componentDidUpdate() {
    const modal = window.bootstrap.Modal.getOrCreateInstance(
      document.getElementById("confirmation-modal")
    );

    modal._element.addEventListener("hidden.bs.modal", () => {
      this.setState({ show: false });
    });

    if (this.state.show) {
      modal.show();
    } else {
      modal.hide();
    }
  }
  render() {
    return (
      <>
        <div className="modal fade" id="confirmation-modal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">{this.state.title}</h1>
              </div>
              <div className="modal-body">{this.state.message}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    this.setState({ show: false });
                    this.state.onNo();
                  }}
                >
                  {this.state.noLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    this.setState({ show: false });
                    this.state.onYes();
                  }}
                >
                  {this.state.yesLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
