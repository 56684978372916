export const getApiEndpoint = () => {
  let endpoint = process.env.REACT_APP_API_ENDPOINT;
  if (endpoint === undefined) {
    switch (process.env.NODE_ENV) {
      case "production":
        endpoint = "https://api.easyhpad.com";
        break;
      default:
      case "development":
        endpoint = "http://localhost:4000";
        break;
    }
    return endpoint;
  }
};

export const appendJwtToken = (url) => {
  let jwtToken = localStorage.getItem("jwt_token");
  if (jwtToken) {
    return `${url}?jwt_token=${jwtToken}`;
  }
  return url;
};
