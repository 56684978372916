import React, { useCallback, useEffect, useState } from "react";

import { gql, useMutation, useQuery } from "@apollo/client";
import logo from "../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../views/common/Loader";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  //on vérifie que le token est valide
  const {
    data: tokenData,
    loading: tokenLoading,
    error: tokenError,
  } = useQuery(
    gql`
      query Query($token: String!) {
        get_with_token(token: $token)
      }
    `,
    {
      variables: { token },
    }
  );

  const [doResetPassword, { data, loading, error }] = useMutation(
    gql`
      mutation Mutation($token: String!, $password: String!) {
        reset_password(token: $token, password: $password)
      }
    `
  );
  useEffect(() => {
    let errors = {};
    let canSubmit = true;
    if (!password) {
      errors.password = "Veuillez saisir votre mot de passe";
      canSubmit = false;
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Veuillez confirmer votre mot de passe";
      canSubmit = false;
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Les mots de passe ne correspondent pas";
      canSubmit = false;
    }

    setErrors(errors);
    setCanSubmit(canSubmit);
  }, [password, confirmPassword, setErrors]);

  useEffect(() => {
    if (error) {
      switch (error?.message) {
        case "user_not_found":
          setErrors({
            username: "Cet identifiant n'existe pas",
          });

          break;
        default:
          setErrors({
            username: "Identifiant incorrect",
          });
          break;
      }
      setValidated(true);
    }
  }, [data, error, setValidated, dispatch, setErrors]);

  const submit = useCallback(
    (event) => {
      (async () => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setErrors({});

        try {
          let res = await doResetPassword({
            variables: {
              token,
              password,
            },
          });
          if (res.data.reset_password) {
            toast.success(
              "Votre mot de passe a été modifié. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe."
            );
            setTimeout(() => {
              window.location.href = "/login";
            }, 3000);
          } else {
            toast.error(
              "Une erreur est survenue lors de la modification du mot de passe."
            );
          }
        } catch (error) {
          console.error(error);
          toast.error(
            "Une erreur est survenue lors de la modification du mot de passe."
          );
        }
      })();
    },
    [doResetPassword, token, password, setValidated]
  );

  if (tokenLoading) {
    return <Loader />;
  }

  return (
    <div className="row w-100">
      <div className="col-md-6">
        <div
          className="d-flex align-items-center w-100"
          style={{
            minHeight: "100vh",
          }}
        >
          <div className="w-100">
            <img
              src={logo}
              className="app-logo w-100 d-block"
              alt="Easyhpad logo"
              style={{ maxWidth: "300px", margin: "auto" }}
            />
          </div>
        </div>
      </div>
      <div
        className="col-md-6"
        style={{
          backgroundColor: "rgb(249, 249, 250)",
          boxShadow: "0 20px 60px rgba(105, 158, 158, 0.3)",
        }}
      >
        <div
          className="flex-row align-items-center"
          style={{
            display: "flex",
            minHeight: "100vh",
          }}
        >
          <div className="w-100" style={{ maxWidth: "25rem", margin: "auto" }}>
            <h1
              className="w-100 text-center mb-4"
              style={{ fontSize: "1.4em", fontWeight: "700" }}
            >
              Choisissez votre nouveau mot de passe
            </h1>
            {(tokenError || !tokenData?.get_with_token?.id) && (
              <div>
                <div className="alert alert-danger">
                  Le lien n'est pas valide, ou a expiré. Veuillez redemander un
                  nouveau lien, ou bien contacter l'assistance.
                </div>
                <div className="mt-4 form-field">
                  <Link to="/forgot-password">Redemander un lien</Link>
                </div>
              </div>
            )}
            {tokenData?.get_with_token.id && (
              <form onSubmit={submit} className="mt-3">
                <div className="mb-4 form-field">
                  <label htmlFor="password" className="form-label">
                    Mot de passe <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (validated && errors.password ? " is-invalid" : "")
                    }
                    id="password"
                    placeholder="Mot de passe"
                    name="password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    value={password}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <div className="mb-4 form-field">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirmation du mot de passe{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (validated && errors.confirmPassword ? " is-invalid" : "")
                    }
                    id="confirmPassword"
                    placeholder="Confirmation du mot de passe"
                    name="confirmPassword"
                    onChange={(event) => {
                      setConfirmPassword(event.target.value);
                    }}
                    value={confirmPassword}
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>

                <div className="mb-4 form-field">
                  <Link to="/login">
                    Se connecter sans changer de mot de passe
                  </Link>
                </div>
                <div className="w-100">
                  <button
                    type="submit"
                    className="btn btn-primary float-end"
                    disabled={loading || !canSubmit}
                    onClick={submit}
                  >
                    Changer de mot de passe
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
